import { useMemo } from 'react';

import { FundingOption } from '@/globalTypes';
import { fundingOptionLabels } from '@/utils/funding-options';

export const useFundingOptions = () => {
  return useMemo<{ label: string; value: string }[]>(() => {
    return Object.values(FundingOption).map((value) => ({
      label: fundingOptionLabels[value],
      value,
    }));
  }, []);
};
